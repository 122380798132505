import {addThrottledInputEventListener} from '../throttled-input'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {on} from 'delegated-events'
import {ready} from '@github-ui/document-ready'
import {SOFT_NAV_STATE} from '@github-ui/soft-nav/states'
import {replaceState} from '@github-ui/browser-history-state'

type Data = {selectors: {[key: string]: string}}

let previousController: AbortController | null = null
let planDuration = 'month'

function getInputs(): NodeListOf<HTMLInputElement> {
  return document.querySelectorAll<HTMLInputElement>('.js-trial-upgrade-seats')
}

function getClosestStepperInput(el: HTMLElement | null): HTMLInputElement | null {
  if (!el) {
    return null
  }
  return el
    .closest('.js-stepper')
    ?.querySelector<HTMLInputElement>('.js-trial-upgrade-seats') as HTMLInputElement | null
}

async function updateTotals(el: HTMLInputElement) {
  const [seatCount] = validateSeats(el)
  const url = new URL(el.getAttribute('data-url')!, window.location.origin)

  const urlWithUpdatedSeats = new URL(window.location.href, window.location.origin)
  const seatName = el.getAttribute('data-url-param-name') || 'seats'

  urlWithUpdatedSeats.searchParams.set(seatName, seatCount)
  replaceState(null, '', urlWithUpdatedSeats.toString())
  updateReturnToUpgradePage()

  const params = new URLSearchParams(url.search.slice(1))
  const inputs = getInputs()
  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i]
    if (!input) {
      continue
    }
    const name = input.getAttribute('data-url-param-name') || 'seats'
    const [count] = validateSeats(input)
    params.append(name, count)
  }
  if (document.querySelectorAll('.js-trial-upgrade-seats').length > 0) {
    planDuration =
      document.querySelector<HTMLInputElement>(
        '.js-org-duration-change:checked,.js-bus-duration-change:checked,.js-bus-duration-input',
      )?.value || 'month'
    params.append('plan_duration', planDuration)
  }

  url.search = params.toString()

  previousController?.abort()
  const {signal} = (previousController = new AbortController())
  let data: Data | null = null
  try {
    const response = await fetch(url.toString(), {signal, headers: {Accept: 'application/json'}})
    if (!response.ok) return
    data = await response.json()
  } catch {
    // ignore network errors
  }
  if (signal.aborted) return
  if (!data) return

  const ref = data.selectors
  for (const selector in ref) {
    for (const element of document.querySelectorAll(selector)) {
      element.innerHTML = ref[selector]!
      const hiddenElement = element.closest<HTMLElement>('.js-stepper-show-with-value')
      if (hiddenElement) {
        hiddenElement.hidden = !ref[selector]
      }
    }
  }
}

function updateReturnToUpgradePage() {
  // When return_to points at the current upgrade page, modify the return_to field so we don't lose user choices when
  // submitting subforms like billing information and payment information on the Enterprise upgrade page.
  const returnToInputs = document.querySelectorAll<HTMLElement>('input[name="return_to"]')
  const currentURL = new URL(window.location.href, window.location.origin)
  for (const returnToInput of returnToInputs) {
    const returnToURL = new URL(returnToInput?.getAttribute('value') || '', window.location.origin)
    if (currentURL.pathname === returnToURL.pathname) {
      returnToInput?.setAttribute('value', currentURL.pathname + currentURL.search)
    }
  }
}

observe('.js-trial-upgrade-seats', {
  constructor: HTMLInputElement,
  add(el) {
    updateTotals(el)

    addThrottledInputEventListener(el, function () {
      updateTotals(el)
    })
  },
})
;(async function () {
  await ready
  const inputs = getInputs()
  const monthRadio = document.getElementById('plan_duration_month') as HTMLInputElement

  if (monthRadio) {
    planDuration = monthRadio.checked ? 'month' : 'year'
  }
  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i] as HTMLInputElement
    limitSeats(input)
    updateTotals(input)
  }
})()

document.addEventListener(SOFT_NAV_STATE.SUCCESS, function () {
  const inputs = getInputs()
  for (let i = 0; i < inputs.length; i++) {
    const input = inputs[i] as HTMLInputElement
    limitSeats(input)
  }
})

on('click', '.js-increase-seats-number', function (e: Event) {
  const input = getClosestStepperInput(e.currentTarget as HTMLElement)
  if (!input || input.disabled) return

  const value: number = Number(input.value) + 1
  input.value = value.toString()
  limitSeats(input)
  updateTotals(input)
})

on('click', '.js-decrease-seats-number', function (e: Event) {
  const input = getClosestStepperInput(e.currentTarget as HTMLElement)
  if (!input || input.disabled) return

  const value: number = Number(input.value) - 1
  input.value = value.toString()
  limitSeats(input)
  updateTotals(input)
})

function limitSeatsOnChange(e: Event) {
  const input = e.target as HTMLInputElement
  if (!input || input.disabled) return

  limitSeats(input)
  updateTotals(input)
}

function validateSeats(input: HTMLInputElement): [clampedSeats: string, hasMinError: boolean, hasMaxError: boolean] {
  const minimumSeats = Number(input.getAttribute('data-minimum')) ?? 1
  const maximumSeats = Number(input.getAttribute('data-maximum')) ?? 1_000 // Matches the maximum default seats limit in configurable/seat_limit_for_upgrade
  const seats = input.value
  const value = Number(seats)
  if ((!value && value !== 0) || value < minimumSeats) {
    return [minimumSeats.toString(), true, false]
  } else if (value > maximumSeats) {
    return [maximumSeats.toString(), false, true]
  } else {
    return [seats, false, false]
  }
}

function limitSeats(input: HTMLInputElement) {
  if (!input || input.disabled) return

  const [clampedSeats, hasMinError, hasMaxError] = validateSeats(input)
  input.value = clampedSeats

  const stepper = input.closest('.js-stepper')
  if (!stepper) return
  stepper.querySelector<HTMLElement>('.js-minimum-seats')!.hidden = !hasMinError
  stepper.querySelector<HTMLElement>('.js-maximum-seats')!.hidden = !hasMaxError
}

observe('.js-trial-upgrade-seats', e => {
  e.addEventListener('change', limitSeatsOnChange)
})
