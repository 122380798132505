import type {Params, RenderInitFields, ZuoraResponse} from '@github/zuorajs'
import {Z} from '@github/zuorajs'
import {debounce} from '@github/mini-throttle'
// eslint-disable-next-line no-restricted-imports
import {observe} from '@github/selector-observer'
// eslint-disable-next-line no-restricted-imports
import {reportError} from '@github-ui/failbot'
import {trackView} from '../hydro-tracking'

async function setupZuoraHostedPaymentsPage(container: HTMLElement) {
  const url = container.getAttribute('data-url')!
  const organizationId = container.getAttribute('data-organization-id')
  const businessId = container.getAttribute('data-business-id')
  const signatureViewContext = container.getAttribute('data-signature-view-context')
  const manualPayment = container.getAttribute('data-manual-payment')
  const termsOfService = container.getAttribute('data-terms-of-service')
  const target = container.getAttribute('data-target')
  const invoices = container.getAttribute('data-invoices')
  const paymentGateway = container.getAttribute('data-payment-gateway')
  const urlQuery = new URLSearchParams()
  if (organizationId) {
    urlQuery.append('organization_id', organizationId)
  }
  if (signatureViewContext) {
    urlQuery.append('view_context', signatureViewContext)
  }
  if (businessId) {
    urlQuery.append('business_id', businessId)
  }
  if (manualPayment) {
    urlQuery.append('manual_payment', manualPayment)
  }
  if (termsOfService) {
    urlQuery.append('terms_of_service', termsOfService)
  }
  if (target) {
    urlQuery.append('target', target)
  }
  if (invoices) {
    for (const invoice of invoices.split(',')) {
      urlQuery.append('invoices[]', invoice)
    }
  }
  if (paymentGateway) {
    urlQuery.append('payment_gateway', paymentGateway)
  }

  const response = await fetch(`${url}?${urlQuery.toString()}`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
  })
  if (!response.ok) {
    const errorMessage = container.getAttribute('data-general-signature-error-message') || ''
    replaceSpinnerWithMessage(errorMessage)
    const responseError = new Error()
    const statusText = response.statusText ? ` ${response.statusText}` : ''
    responseError.message = `HTTP ${response.status}${statusText}`
    throw responseError
  }
  const params: Params = await response.json()

  if (container.classList.contains('js-zuora-payment-page')) {
    if (params['error']) {
      replaceSpinnerWithMessage(params['error'])
      return
    }

    const userId = container.getAttribute('data-id')!
    loadZuora(container, params, {creditCardHolderName: userId}, paymentMethodPageCallback)
  } else {
    loadZuora(container, params, {creditCardHolderName: 'GitHub Organization'}, invoicePaymentCallback)
  }
}

type PaymentMethodDetails = {
  masked_number: string
  card_type: string
}

async function fetchPaymentMethodDetails(
  zuoraResponse: ZuoraResponse,
  orgId: string | null,
  callback: (response: PaymentMethodDetails) => void,
) {
  const params = new URLSearchParams()
  params.append('payment_method_id', zuoraResponse.refId)
  if (orgId) {
    params.append('organization_id', orgId)
  }

  const response = await fetch(`/settings/billing/payment_method?${params.toString()}`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
      Accept: 'application/json',
    },
  })
  if (!response.ok) {
    const responseError = new Error()
    const statusText = response.statusText ? ` ${response.statusText}` : ''
    responseError.message = `HTTP ${response.status}${statusText}`
    throw responseError
  }

  callback(await response.json())
}

function errorMessageCallback(container: HTMLElement) {
  return (key: string, code: string, message: string) => {
    let errorMessage = message
    let errorCode = ''
    const errorMessages = JSON.parse(container.getAttribute('data-error-messages')!)

    // Regular expression that captures the text within the first set of square brackets
    // e.g. "[ThreeDs2_Authentication_Exception] Transaction declined.402 - [card_error/card_declined/stolen_card] Your card was declined."
    const errorCodeRegex = /\[(.*?)\]/

    // Applying the regex to the string
    const match = errorMessage.match(errorCodeRegex)

    if (match && typeof match[1] === 'string') {
      // The captured substring is in the first capturing group
      errorCode = match[1]
    }

    const clientContext = {
      processor_response_reason: errorMessage,
      processor_response_code: errorCode,
    }

    switch (key) {
      case 'error':
        // Send error info to Hydro
        container.setAttribute('data-hydro-client-context', JSON.stringify(clientContext))
        trackView(container)

        if (errorCode === 'Attempt_Exceed_Limitation') {
          errorMessage = errorMessages['attempts_exceeded_limit_error']
        } else {
          errorMessage = errorMessages['processor_declined_error']
        }
        break
    }
    Z.sendErrorMessageToHpm(key, errorMessage)
  }
}

type ZuoraCallback = (response: ZuoraResponse) => void

async function pollPayment(
  paymentId: string,
  organizationId?: string | null,
  maxRetries = 5,
  timeBetweenRequests = 1000,
): Promise<Response> {
  if (maxRetries === 0) {
    throw new Error(`Max retries exhausted`)
  }

  let checkUrl = `/settings/billing/bill_pay/${paymentId}`
  if (organizationId) {
    const urlQuery = new URLSearchParams()
    urlQuery.append('organization_id', organizationId)
    checkUrl = `${checkUrl}?${urlQuery.toString()}`
  }

  const response = await fetch(checkUrl, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  })
  if (response.status === 200) {
    return response
  }
  if (response.status === 202) {
    await new Promise(resolve => setTimeout(resolve, timeBetweenRequests))
    return pollPayment(paymentId, organizationId, maxRetries - 1, timeBetweenRequests * 1.5)
  }

  throw new Error(`Unexpected ${response.status} response from poll endpoint`)
}

function replaceLocation(url: string | null) {
  if (!url) {
    return
  }

  try {
    const validatedUrl = new URL(url, window.location.origin)
    if (validatedUrl.protocol === 'http:' || validatedUrl.protocol === 'https:') {
      window.location.replace(validatedUrl.toString())
    }
  } catch (_) {
    return
  }
}

function zuoraSuccessCallback(container: HTMLElement, callback: ZuoraCallback): ZuoraCallback {
  return async response => {
    callback(response)

    if (response.success === 'true') {
      const form = document.querySelector('.js-payment-method-form')
      if (form) {
        if (!(form instanceof HTMLFormElement)) return
        // We are in a page with only the credit card form that can be submitted
        const redirectTo = container.getAttribute('data-redirect-to')
        if (redirectTo) {
          // redirectTo is only added when we are in the manual payment page. Poll for the transaction to be completed
          if (response.AuthorizeResult === 'Approved') {
            const orgId = container.getAttribute('data-organization-id')

            const spinner = document.querySelector<HTMLElement>('.js-payment-verification-spinner')!
            const success = document.querySelector<HTMLElement>('.js-payment-verification-success')!
            const paymentWrapper = document.querySelector<HTMLElement>('.js-payment-method-section-wrapper')!
            const stillProcessing = document.querySelector<HTMLElement>('.js-payment-still-processing')!

            try {
              paymentWrapper.hidden = true
              spinner.hidden = false

              await pollPayment(response.PaymentId, orgId)

              spinner.hidden = true
              success.hidden = false
            } catch (error) {
              spinner.hidden = true
              stillProcessing.hidden = false
              await new Promise(resolve => setTimeout(resolve, 3000))
            }

            await new Promise(resolve => setTimeout(resolve, 150))
            replaceLocation(redirectTo)
          } else {
            // Unlikely we'll hit this point but because Zuora doesn't have good documentation
            // Sending an error to Sentry is the best way to get awareness that there's other cases
            reportError(new Error(`Unknown AuthorizationResult ${response.AuthorizeResult}`))
            replaceLocation(redirectTo)
          }
        } else {
          form.submit()
        }
      } else {
        const orgId = container.getAttribute('data-organization-id')
        fetchPaymentMethodDetails(response, orgId, (res: PaymentMethodDetails) => {
          const infoContainer = document.querySelector<HTMLElement>('.js-zuora-billing-info')!
          const newCardTypeContainer = infoContainer.querySelector<HTMLElement>('.js-new-card-type')!
          const newCardNumberContainer = infoContainer.querySelector<HTMLElement>('.js-new-card-number')!
          const newCardLastFour = res.masked_number.replace(/\*/g, '')
          const paymentMethodsContainer = container.closest<HTMLElement>('.js-billing-section')!
          const awaitingPayment = document.querySelector('.js-awaiting-payment')

          newCardTypeContainer.textContent = res.card_type
          newCardNumberContainer.textContent = newCardLastFour
          /* eslint-disable-next-line github/no-d-none */
          container.classList.add('d-none')
          /* eslint-disable-next-line github/no-d-none */
          infoContainer.classList.remove('d-none')

          if (awaitingPayment) {
            awaitingPayment.toggleAttribute('hidden')
          }

          paymentMethodsContainer.classList.remove('PaymentMethod--creditcard')
          paymentMethodsContainer.classList.add('PaymentMethod--creditcard-added')
        })
      }
    }
  }
}

function loadZuora(container: HTMLElement, params: Params, initFields: RenderInitFields, callback: ZuoraCallback) {
  const initFieldsWithPopulatedFields = Object.assign({}, params.prepopulate, initFields)
  delete params.prepopulate

  Z.renderWithErrorHandler(
    params,
    initFieldsWithPopulatedFields,
    zuoraSuccessCallback(container, callback),
    errorMessageCallback(container),
  )
}

function paymentMethodPageCallback(response: ZuoraResponse) {
  if (response.success === 'true') {
    const infoContainer = document.querySelector<HTMLElement>('.js-zuora-billing-info')!
    const paymentMethodIDEl = infoContainer.querySelector<HTMLInputElement>('.js-zuora-payment-method-id')!
    const countryCodeEl = infoContainer.querySelector<HTMLInputElement>('.js-zuora-select-country')!
    const regionEl = infoContainer.querySelector<HTMLInputElement>('.js-zuora-select-region')!
    const postalCodeEl = infoContainer.querySelector<HTMLInputElement>('.js-zuora-select-postal-code')!

    paymentMethodIDEl.value = response.refId
    countryCodeEl.value = countryCodeEl.value || response.creditCardCountry || ''
    regionEl.value = regionEl.value || response.creditCardState || ''
    postalCodeEl.value = postalCodeEl.value || response.creditCardPostalCode || ''
  }
}

function invoicePaymentCallback(response: ZuoraResponse) {
  const params = new URLSearchParams()
  params.append('success', response.success)
  params.append('response_from', response.responseFrom)
  if (response.success) {
    params.append('ref_id', response.refId)
  } else {
    params.append('error_code', response.errorCode)
    params.append('error_message', response.errorMessage)
  }
  const redirectUrl = `pay?${params.toString()}`
  window.location.replace(redirectUrl)
}

function resizeZuoraIframe() {
  const iframe = document.getElementById('z_hppm_iframe')

  if (iframe != null) {
    if (window.innerWidth < 544) {
      iframe.style.width = '400px'
      iframe.style.height = '350px'
    } else if (window.innerWidth < 768) {
      iframe.style.width = '95%'
      iframe.style.height = '300px'
    } else {
      iframe.style.width = '95%'
    }

    const container = document.getElementById('zuora_payment')
    if (container) {
      iframe.setAttribute('title', container.getAttribute('title')!)
    }
    Z.post(iframe.id, 'resize')
  }
}

function replaceSpinnerWithMessage(message: string) {
  const spinner = document.querySelector('.js-zuora-spinner')
  if (spinner) spinner.textContent = message
}

observe('#zuora_payment', {
  constructor: HTMLElement,
  add(el) {
    const cardFieldsPage = el.classList.contains('js-zuora-payment-page')
    if (cardFieldsPage) {
      const spinner = document.querySelector('.js-zuora-spinner')
      /* eslint-disable-next-line github/no-d-none */
      if (spinner) spinner.classList.remove('d-none')
      el.style.opacity = '0'

      Z.runAfterRender(() => {
        /* eslint-disable-next-line github/no-d-none */
        if (spinner) spinner.classList.add('d-none')
        el.style.opacity = '1'

        /* eslint-disable-next-line github/prefer-observers */
        window.addEventListener('resize', debounce(resizeZuoraIframe, 200))
      })
    }

    setupZuoraHostedPaymentsPage(el)
  },
})
